<template>
     <div>
        <CModalExtended
            color="dark"
            :closeOnBackdrop="false"
             class="modal-extended"
            :show.sync="$store.state.contenedores.modalStatus"
            :title="tituloModal"
            :close-on-backdrop="false"

        >
            <CRow>
                <CCol  sm="12" lg="12">
                    <CInput
                        :label="$t('label.name')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        v-uppercase
                        :placeholder="$t('label.name')+' '+$t('label.status')"
                        v-model="$v.formStatus.TpCargoStatusName.$model"
                        @blur="$v.formStatus.TpCargoStatusName.$touch()"
                        :addInputClasses="{ 'is-invalid': $v.formStatus.TpCargoStatusName.$error }"
                        required 
                        :invalid-feedback="errorMessage($v.formStatus.TpCargoStatusName)"
                        :is-valid="hasError($v.formStatus.TpCargoStatusName)"
                    >
                    </CInput>
                </CCol>
                <CCol sm="12" lg="12" >
                    <CSelect
                        :label="$t('label.status')"
                        addLabelClasses="required text-right"
                        :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                        :is-valid="formStatus.FgActTpCargaStatus"
                        v-if="!idIndentification"
                        v-model="formStatus.FgActTpCargaStatus"
                        :value.sync="formStatus.FgActTpCargaStatus"
                        :options="selectOptions"
                    />
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div v-if="apiStateFormLoading || loading">
                        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        {{$t('button.accept')}}
                    </div>

                    <div v-if="!apiStateFormLoading && !loading">
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="cerrarModal"
                    class="m-2"
                    :disabled="apiStateFormLoading || loading "
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
                
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import ENUM from '@/_store/enum';
    import { FormStatus } from '@/_validations/contenedores/ContenedoresValidation';
    import { mapState } from 'vuex';
    import UpperCase  from '@/_validations/uppercase-directive';
    import {alertPropertiesHelpers} from '@/_helpers/funciones';
    import GeneralMixin from '@/_mixins/general';
    //data
    function data() {
        return {
            formStatus:{
                TpCargoStatusId:0,
                TpCargoStatusName:'',
                FgActTpCargoStatus:true,
            },
            originalAct: '',
            tituloModal: this.$t('label.nuevo')+' '+this.$t('label.name')+' '+this.$t('label.status'),

            loading:false,
        }
    }
    //methods
    function cambio(e){
        this.formEstado.FgActEstado = !this.formEstado.FgActEstado;
    }
    function cerrarModal(){
        if(this.apiStateFormLoading || this.loading)
            return
        this.$store.state.contenedores.modalStatus = false;
    }
    function registerData(){
       
        try {
                this.$v.formStatus.$touch();
             if (this.$v.formStatus.$pending || this.$v.formStatus.$error)  {
                throw this.$t('label.errorsPleaseCheck');
             } 

                let { TpCargoStatusId,TpCargoStatusName,FgActTpCargaStatus } = this.formStatus;
                const { dispatch } = this.$store;
                if(this.originalAct !== '' && TpCargoStatusId !== 0){
                    if(this.originalAct !== FgActTpCargaStatus){
                        this.$swal.fire(
                            alertPropertiesHelpers(this, {
                                text: `${this.$t('label.changeStatusQuestion')+' '} ${TpCargoStatusName}?`,
                            })
                        ).then((result) => {
                            if (result.isConfirmed) {
                                dispatch('contenedores/dataStatus', { TpCargoStatusId,TpCargoStatusName,FgActTpCargoStatus:FgActTpCargaStatus });
                            }else {
                                //this.$swal(this.$t('label.operationCancelled'));
                            }
                        })
                    }else{
                        dispatch('contenedores/dataStatus', { TpCargoStatusId,TpCargoStatusName,FgActTpCargoStatus:FgActTpCargaStatus });
                    }
                }else{
                    dispatch('contenedores/dataStatus', { TpCargoStatusId,TpCargoStatusName,FgActTpCargoStatus:FgActTpCargaStatus });
                }
         } catch (e) {
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: e,
                type: "error"
            });
        }
    }
    //computed
    function idIndentification(){
        return this.formStatus.TpCargoStatusId === 0;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }

    function selectOptions (){
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    //watch
    function modalStatus(newQuestion){
        if(newQuestion === false){
            this.formStatus.TpCargoStatusId=0;
            this.formStatus.TpCargoStatusName='';
            
            this.originalAct = '';
            this.tituloModal = this.$t('label.nuevo')+' '+this.$t('label.name')+' '+this.$t('label.status');
            this.formStatus.FgActTpCargaStatus=true;
            this.$nextTick(() => { this.$v.$reset() })
            this.$store.commit('contenedores/asignarid', 0);
        }else{
            this.tituloModal = this.$t('label.nuevo')+' '+this.$t('label.name')+' '+this.$t('label.status');
            if(this.idState !== 0){
                const id = this.idState;
                this.$store.state.contenedores.apiStateForm = ENUM.LOADING;
                this.$http.ejecutar('GET', 'TpCargoStatus-by-id',{ TpCargoStatusId: id }, '' ).then(response => {
                    try {
                        if(response.status === 200){
                            const Information = response.data.data;
                            if(Information.length !== 0){
                                this.tituloModal = this.$t('label.edit')+' '+this.$t('label.name')+' '+this.$t('label.status')+': '+Information[0].TpCargoStatusName;
                                this.formStatus.TpCargoStatusId= Information[0].TpCargoStatusId;
                                this.formStatus.TpCargoStatusName= Information[0].TpCargoStatusName;
                                this.originalAct = Information[0].FgActTpCargoStatus;
                                this.formStatus.FgActTpCargaStatus= Information[0].FgActTpCargoStatus;
                                this.$store.state.contenedores.apiStateForm = ENUM.INIT;
                            }
                            this.$v.$touch();
                        }else{
                            this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                            this.$store.state.contenedores.modalStatus = false;
                        }
                    } catch (error) {
                        this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                        this.$store.state.contenedores.modalStatus = false;
                        this.$store.commit('contenedores/messageMutation', error);
                    }
                }).catch(err => {
                    this.$store.state.contenedores.apiStateForm = ENUM.ERROR;
                    this.$store.state.contenedores.modalStatus = false;
                    this.$store.commit('contenedores/messageMutation', err);
                });
            }
        }
    }

    export default {
        name: 'modal-status',
        data,
        validations(){ return FormStatus() },
        methods:{
            registerData,
            cambio,
            cerrarModal,
            //errorMessage,
        },
        mixins: [GeneralMixin ],
        directives: UpperCase,
        computed:{
            idIndentification,
            apiStateFormLoading,
            selectOptions,
            //tituloModal,
            ...mapState({
                idState: state=> state.contenedores.id,
                apiState: state => state.contenedores.apiState,
                apiStateForm: state => state.contenedores.apiStateForm,
                modalStatus: state=> state.contenedores.modalStatus,
            })
        },
        watch:{
            modalStatus
        }
    }
</script>
